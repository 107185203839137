import React from 'react';
import { useSelector } from 'react-redux';
import { Telegram, Discord } from 'react-bootstrap-icons';
import { BsGift } from 'react-icons/bs';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { ReactComponent as XLogo } from 'src/assets/XLogo.svg';

import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import { AlertCopy } from '../Table/components/AlertCopy';

import { handleCopy } from 'src/constants';

export const ReferralModal = ({ onClose }) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  const address = 'http://r.clbh.se/mvgen75';

  return (
    <Modal isOpen onClose={onClose}>
      <Container>
        <Container fontSize="1.25rem" fontWeight={500} textAlign="center" marginBottom="1rem">
          Earn rewards for referring your friends
        </Container>
        <Container paddingBottom="0.25rem">Your unique share link:</Container>
        <Container display="flex" gap="0.5rem" marginBottom="1.5rem">
          <Container padding="0.5rem 1rem" background="#ccc" borderRadius="0.5rem" flexGrow={1}>
            {address}
          </Container>
          <Button text="Copy" onClick={() => handleCopy(address)} />
        </Container>
        <Container display="flex" justifyContent="space-between" marginBottom="2rem">
          <Container display="flex" gap="0.5rem" alignItems="center">
            <Container>Referrals Pending: 0 |</Container>
            <Container>Referrals Achieved: 0</Container>
          </Container>
          <Container display="flex" gap="1rem">
            <a
              href="https://x.com/bxlend_"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <XLogo height="2rem" fill={isDark ? '#fff' : '#111'} width={25} />
            </a>
            <a
              href="https://t.me/bxlend"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Telegram size="2rem" color="#0088CC" width={25} />
            </a>
            <a
              href="https://discord.gg/Kg8z3zNP"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Discord size="2rem" color="#5865F2" width={25} />
            </a>
          </Container>
        </Container>
        <Container width="100%" display="flex">
          <Container
            display="flex"
            flexDirection="column"
            width="50%"
            borderRight="1px solid #ccc"
            textAlign="center"
            padding="0 1rem 1rem 1rem"
          >
            <Container
              fontWeight={600}
              width="calc(100% + 3.3rem)"
              marginLeft="-2.25rem"
              padding="0.5rem 0"
              background="#ccc"
            >
              Your friend will receive...
            </Container>
            <Container></Container>
            <Container
              margin="auto"
              fontSize="1.5rem"
              paddingBottom="1rem"
              borderBottom="1px solid #ccc"
            >
              <BsGift size={30} />
              <Container>2 Months Free</Container>
            </Container>
            <Container marginTop="auto">
              Your referral gets two months free on any paid plan
            </Container>
          </Container>
          <Container
            display="flex"
            flexDirection="column"
            width="50%"
            textAlign="center"
            padding="0 1rem 1rem 1rem"
          >
            <Container
              fontWeight={600}
              marginBottom="1rem"
              width="calc(100% + 3.3rem)"
              marginLeft="-1rem"
              padding="0.5rem 0"
              background="#ccc"
            >
              You will earn...
            </Container>
            <Container paddingBottom="1.5rem">
              <FaRegCalendarAlt size={30} />
            </Container>
            <Container marginBottom="1rem">
              <Container fontWeight={500}>Airpods or $250 Amazon Gift Card</Container>
              <Container fontSize="0.9rem">
                <Container display="inline" fontWeight={600}>
                  1
                </Container>{' '}
                Referral
              </Container>
            </Container>
            <Container marginBottom="1rem">
              <Container fontWeight={500}>Airpods or $250 Amazon Gift Card</Container>
              <Container fontSize="0.9rem">
                <Container display="inline" fontWeight={600}>
                  2
                </Container>{' '}
                Referral
              </Container>
            </Container>
            <Container marginBottom="1rem">
              <Container fontWeight={500}>iPad Pro + accessories</Container>
              <Container fontSize="0.9rem">
                <Container display="inline" fontWeight={600}>
                  3
                </Container>{' '}
                Referrals
              </Container>
            </Container>
            <Container marginBottom="1rem">
              <Container fontWeight={500}>Macbook Pro</Container>
              <Container fontSize="0.9rem">
                <Container display="inline" fontWeight={600}>
                  5
                </Container>{' '}
                Referrals
              </Container>
            </Container>
          </Container>
        </Container>
        <AlertCopy />
      </Container>
    </Modal>
  );
};
