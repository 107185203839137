import React from 'react';

import { Container } from 'src/components/Container';
import { Input } from 'src/components/Input';
import { formatNumberWithSpaces } from 'src/constants';
import GradientedArea from 'src/pages/Presale/components/GradientedArea';

const StepOne = ({ presaleInfo, amountUsd, setAmountUsd }) => {
  return (
    <Container padding="1.25rem" border="1px solid #ccc" borderRadius="1.25rem">
      <Container
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
        marginBottom="5rem"
        gap="4rem"
      >
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing="0.6rem">BONUS</Container>
              <Container fontSize="3.75rem">{presaleInfo.discounts[0].discount * 100}%</Container>
            </Container>
          </GradientedArea>
          <Container>
            Get{' '}
            <span style={{ color: 'green', fontWeight: 600 }}>
              {formatNumberWithSpaces(
                (presaleInfo.discounts[0].minimum_buy / 100) *
                  (presaleInfo.discounts[0].discount * 100),
              )}
            </span>{' '}
            BXT extra on
            <Container>
              <span style={{ color: 'green', fontWeight: 600 }}>
                {formatNumberWithSpaces(presaleInfo.discounts[0].minimum_buy)}
              </span>
              <span> BXT</span>
            </Container>
          </Container>
        </Container>
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing={10}>BONUS</Container>
              <Container fontSize="3.75rem">{presaleInfo.discounts[1].discount * 100}%</Container>
            </Container>
          </GradientedArea>
          <Container>
            <Container>
              Get{' '}
              <span style={{ color: 'green', fontWeight: 600 }}>
                {formatNumberWithSpaces(
                  (presaleInfo.discounts[1].minimum_buy / 100) *
                    (presaleInfo.discounts[1].discount * 100),
                )}
              </span>{' '}
              BXT extra on
              <Container>
                <span style={{ color: 'green', fontWeight: 600 }}>
                  {formatNumberWithSpaces(presaleInfo.discounts[1].minimum_buy)}
                </span>
                <span> BXT</span>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing="0.6rem">BONUS</Container>
              <Container fontSize="3.75rem">{presaleInfo.discounts[2].discount * 100}%</Container>
            </Container>
          </GradientedArea>
          <Container>
            Get{' '}
            <span style={{ color: 'green', fontWeight: 600 }}>
              {formatNumberWithSpaces(
                (presaleInfo.discounts[2].minimum_buy / 100) *
                  (presaleInfo.discounts[2].discount * 100),
              )}
            </span>{' '}
            BXT extra on
            <Container>
              <span style={{ color: 'green', fontWeight: 600 }}>
                {formatNumberWithSpaces(presaleInfo.discounts[2].minimum_buy)}
              </span>
              <span> BXT</span>
            </Container>
          </Container>
        </Container>
        <Container textAlign="center">
          <GradientedArea>
            <Container padding="0 3rem" textAlign="center">
              <Container letterSpacing="0.6rem">BONUS</Container>
              <Container fontSize="3.75rem">{presaleInfo.discounts[3].discount * 100}%</Container>
            </Container>
          </GradientedArea>
          <Container>
            Get{' '}
            <span style={{ color: 'green', fontWeight: 600 }}>
              {formatNumberWithSpaces(
                (presaleInfo.discounts[3].minimum_buy / 100) *
                  (presaleInfo.discounts[3].discount * 100),
              )}
            </span>{' '}
            BXT extra on
            <Container>
              <span style={{ color: 'green', fontWeight: 600 }}>
                {formatNumberWithSpaces(presaleInfo.discounts[3].minimum_buy)}
              </span>
              <span> BXT</span>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container textAlign="center" paddingBottom="1.25rem" fontSize="1.75rem">
        Step 1
      </Container>
      <Container>
        <Container paddingBottom="1.25rem" textAlign="center">
          Enter the amount in USD you want to spend to purchase BXT tokens
        </Container>
        <Container maxWidth="30rem" margin="0 auto">
          <Input
            label="Amount in USD"
            value={amountUsd}
            type="number"
            onChange={(e) => setAmountUsd(e.currentTarget.value)}
          />
        </Container>
      </Container>
      <Container display="flex" justifyContent="space-around"></Container>
    </Container>
  );
};

export default StepOne;
